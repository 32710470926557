//-- Backgrounds

.bg-1 {
  @include background2("https://picsum.photos/id/1018/1920/1280", #000, 0.3);
}

.bg-gradient-1 {
  @include background("", linear-gradient(45deg, #e66465, #9198e5));
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 3vw solid white;
}

.bg-white {
  background-color: white;
}

.bg-second {
  background-color: $brand-second;
}

.bg-light {
  background-color: $gray-light;
}

.bg-lighter {
  background-color: $gray-lighter;
}

.bg-gray {
  background-color: $gray;
}

.bg-dark {
  background-color: $gray-dark;
}

.bg-darker {
  background-color: $gray-darker;
}

.bg-danger {
  background-color: $brand-danger;
}

.bg-success {
  background-color: $brand-success;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-overlay {
  position: relative;
  > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .bg-overlay__filter {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(115, 172, 29, 0.2);
    }
  }
  > .bg-overlay__content {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}


body {
  background: url(../images/parallax/2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


.temoignage {
	@extend .text-center;
	padding: 5rem;
	margin: 6rem auto 3rem;
	max-width: 100rem;
	font-weight: 300;
	font-style: italic;
	border: solid .2rem $brand-second;
	position: relative;

	&::after {
		content: '\f10d';
		font-family: 'fontAwesome';
		position: absolute;
		top: -4rem;
		left: 50%;
		width: 6rem;
		height: 6rem;
		line-height: 6rem;
		margin-left: -3rem;
		background-color: $brand-second;
		border-radius: 50%;
		text-align: center;
		color: white;
		font-style: normal;
	}
}